body::-webkit-scrollbar{
  display: none;
  background-color: transparent;
}

div::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  display: none;
  background-color: transparent;
}
 
div::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}
 
div::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: none;
  border: none;
}

div::-webkit-scrollbar-corner {
  background-color: transparent;
}

.microsite {
	height: 100vh;

	.product-modal-container {
		z-index: 1000;
		background-color: transparent;
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: 0.25s;
	}

	.product-modal-container.active {
		pointer-events: auto;
	}

	.product-modal-container-background {
		background-color: rgba(0,0,0,0.2);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		transition: 0.5s;
	}

	.header {
		top: 0;
		width: calc(100% - 20px);
		padding: 0 10px;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		z-index: 50;
		background-color: white;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
		// opacity: 0.2;

		.header-img {
			padding: 0;
			margin: 0;
		}

		.header-img-link {
			height: 100%;
			display: flex;
			align-items: center;
			margin: 0;
			padding: 0;
		}

		.shim {
			height: 100%;
			width: 60px;
			margin: 0 10px;
		}

		.cart-button {
			height: 100%;
			width: 50px;	
			outline: none;
			border: none;
			background-color: transparent;
			cursor: pointer;
			position: relative;

			.cart-img {
				max-width: 50px;
				max-height: 50px;
			}

			.cart-count {
				position: absolute;
				right: 0px;
				bottom: 5px;
				background-color: red;
				border-radius: 1000px;
				min-height: 17px;
				min-width: 17px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: white;
				font-size: 10px;
				font-weight: bold;
			}
		}

		img {
			height: 90%;
			max-width: 400px;
			object-fit: contain; 
		}
	}

	.site-footer {
		width: 100%;
		height: 40px;
		text-align: center;
		margin-top: 25px;
		opacity: 0.5;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		line-height: 30px;
		padding-bottom: 200px;

		a {
			text-decoration: none;
		}

		hr {
			width: 20px;
			height: 0;
			border: 0.5px solid #ececec;
		}
	}

	.footer {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 50px;
		z-index: 0;

		.footer-carousel::-webkit-scrollbar {
		  background-color: transparent;
		  display: none;
		  height: 0;
		  width: 0;
		}

		a {
			text-decoration: none;
			outline: 0;
			border: 0;
			color: black;
			height: 45px;
			padding: 0 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: color 0.25s, background-color 0.25s, border 0.25s;
			font-size: 0.9em;
			font-weight: bold;
		}
	}
}


.show-cart-enter {
	transform: translateX(400px);
}
.show-cart-enter-active {
	transform: translateX(0);
	transition: 250ms;
}
.show-cart-exit {
  opacity: 1;
  transform: translateX(0);
}
.show-cart-exit-active {
  transform: translateX(400px);
  transition: 250ms;
}
