.cart-super-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	z-index: 100;
}

.cart-background {
	flex:  1;
	height: 100%;
	width: 100%;
	background-color: transparent;
}

.cart-container {
	height: 100vh;
	width: 90%;
	max-width: 400px;
	background-color: white;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;

	.cart-footer {
		display: flex;
		flex-direction: column;
		position: sticky;
		flex: 0;
		padding: 20px;
		padding-top: 0px;
		bottom: 0;
		width: calc(100% - 40px);
		background-color: white;
		border-top: 1px solid #efefef;
		min-height: 105px;
		width: 90%;
		max-width: 400px;

		.subtotal-line {
			display: flex;
			flex-direction: row;
			flex: 1;
			align-items: space-between;
			justify-content: space-between;
			padding: 0px 2px 5px 2px;

			.label {
				font-weight: bold;
				font-size: 0.9em;
				text-align: left;
			}
			.cart-price {
				font-weight: bold;
				font-size: 0.9em;
				text-align: right;
			}
		}

		.checkout-button {
			text-decoration: none;
			color: white;
			margin: 0;

			outline: 0;
			border: 0;
			height: 45px;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.2s, color 0.2s, border 0.2s;
			font-size: 14px;
			font-weight: bold;

			letter-spacing: 1px;

			display: flex;
			justify-content: center;
			align-items: center;

			margin-bottom:0px;

			z-index: 100;

			text-transform: uppercase;

			background-color: #3781D8;
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		}

		.checkout-button.disabled {
			pointer-events: none;
			opacity: 0.6;
		}

		.checkout-button:hover {
			// background-position: 100% 50%;
			background-color: #E73C7E;
			color: white;
		}
	}

	.cart-header {
		height: 60px;
		padding-left: 22px;
		padding-right: 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #efefef;

		img {
			height: 30px;
			width: 30px;
			cursor: pointer;
		}
	}

	.items {
		display: flex;
		flex-direction: column;
		padding-top: 15px;
		padding-bottom: 120px;
		min-height: 10px;
		flex: 1;
		overflow: scroll;

		.cart-item {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 5px 15px;
			flex-shrink: 0;

			img {
				height: 60px;
				width: 60px;
				object-fit: contain;
				margin-right: 10px;
			}
			
			.item-right {
				display: flex;
				flex-direction: column;
				flex: 1;
				// border-bottom: 1px solid #E7E7E7;
				margin-bottom: 5px;
				padding-bottom: 5px;

				.quantity-container {
					display: flex;
					flex-direction: row;
					padding: 10px 10px;
					opacity: 0.4;
					font-size: 16px;

					.control {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 20px;
						width: 20px;
						border-radius: 10px;
						cursor: pointer;
						transition: 0.2s;
						user-select: none;
					}

					.control:active {
						background-color: black;
						color: white;
					}

					.quantity {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 20px;
						width: 20px;
						margin: 0 5px;
					}
				}

				.item-text {
					display: flex;
					flex-direction: row;
					flex-grow: 1;
					padding: 0 10px;

					.cart-item-variant {
						padding-top: 5px;
						font-size: 14px;
					}

					.cart-item-title {
						font-weight: bold;
						font-size: 16px;
						line-height: 24px;
						flex: 1;
						text-align: left;
					}
					.cart-item-price {
						font-weight: medium;
						font-size: 14px;
						line-height: 24px;
						width: 60px;
						text-align: right;
						color: #2f2f2f;
					}
				}
			}
		}
	}
}